export type Region = "staging" | "stage2" | "au" | "eu" | "us" | "ca";
export type ReportingRegion =
  | "ap-southeast-2"
  | "eu-west-1"
  | "us-east-1"
  | "ca-central-1";

export interface Config {
  baseUrl: string;
  regionName: string;
  region: Region;
  reportingRegion: ReportingRegion;
  auth0Audience: string;
  auth0Domain: string;
  auth0ClientId: string;
  adminApi: string;
  equiemEdition?: string;
  installation: string;
  isProduction: boolean;
  addToIrisLink: string;
  eqOneUrl: string;
  permissionsSystem: {
    irisUrl: string;
    checkinUrl: string;
    vmsUrl: string;
    cortexUrl: string;
  };
  featureToggles: {
    fromAddress: boolean;
    integrations: boolean;
    swiftconnect: boolean;
    equiemEssentials: boolean;
    accessOne: boolean;
  };
  areaUnits: "sqm" | "sqf";
}

export const test: Config = {
  baseUrl: "https://us.admin.staging.getequiem.com",
  regionName: "Test",
  region: "staging",
  reportingRegion: "ap-southeast-2",
  auth0Audience: "",
  auth0Domain: "",
  auth0ClientId: "",
  adminApi: "",
  installation: "ap-southeast-2-01",
  isProduction: false,
  addToIrisLink: "https://stage.iris.equiem.com.au/users/new",
  eqOneUrl: "https://one.staging.getequiem.com/",
  permissionsSystem: {
    irisUrl: "https://stage.iris.equiem.com.au/",
    checkinUrl: "https://checkin.staging.getequiem.com/",
    vmsUrl: "https://us.vendor.staging.getequiem.com/",
    cortexUrl: "https://staging.console.getequiem.com/",
  },
  featureToggles: {
    fromAddress: true,
    integrations: true,
    swiftconnect: true,
    equiemEssentials: true,
    accessOne: false,
  },
  areaUnits: "sqm",
};

export const stagingUs: Config = {
  baseUrl: "https://us.admin.staging.getequiem.com",
  regionName: "Staging",
  region: "staging",
  auth0Audience: "https://equiem-syndicate-api-staging.herokuapp.com/",
  auth0Domain: "staging.login.getequiem.com",
  auth0ClientId: "VHAmGO7qy0IHyL8r3slRDqFnOZPFwvsS",
  adminApi: "https://staging.gateway.getequiem.com/graphql",
  installation: "ap-southeast-2-01",
  reportingRegion: "ap-southeast-2",
  isProduction: false,
  eqOneUrl: "https://one.staging.getequiem.com/",
  featureToggles: {
    fromAddress: true,
    integrations: true,
    swiftconnect: true,
    equiemEssentials: true,
    accessOne: true,
  },
  addToIrisLink: "https://iris.staging.getequiem.com/users/new",
  permissionsSystem: {
    irisUrl: "https://iris.staging.getequiem.com/",
    checkinUrl: "https://checkin.staging.getequiem.com/",
    vmsUrl: "https://us.vendor.staging.getequiem.com/",
    cortexUrl: "https://staging.console.getequiem.com/",
  },
  areaUnits: "sqm",
};

export const stagingAu: Config = {
  baseUrl: "https://au.admin.staging.getequiem.com",
  regionName: "Stage2",
  region: "stage2",
  auth0Audience: "https://equiem-syndicate-api-staging.herokuapp.com/",
  auth0Domain: "staging.login.getequiem.com",
  auth0ClientId: "VHAmGO7qy0IHyL8r3slRDqFnOZPFwvsS",
  adminApi: "https://au.gateway.staging.getequiem.com/graphql",
  installation: "stage2",
  reportingRegion: "ap-southeast-2",
  isProduction: false,
  addToIrisLink: "https://iris.staging.getequiem.com/users/new",
  eqOneUrl: "https://one.staging.getequiem.com/",
  featureToggles: {
    fromAddress: true,
    integrations: true,
    swiftconnect: true,
    equiemEssentials: true,
    accessOne: false,
  },
  permissionsSystem: {
    irisUrl: "https://iris.staging.getequiem.com/",
    checkinUrl: "https://checkin.staging.getequiem.com/",
    vmsUrl: "https://stage2.vendor.getequiem.com/",
    cortexUrl: "https://stage2.console.getequiem.com/",
  },
  areaUnits: "sqf",
};

export const local: Config = {
  ...stagingUs,
  baseUrl: "https://admin.eq:4200",
};

const prod = {
  auth0Audience: "https://syndicate.getequiem.com/",
  auth0ClientId: "ifwAZRZvVWYehCcxLlymFhC2i41JrAW1",
  auth0Domain: "login.getequiem.com",
  isProduction: true,
  addToIrisLink: "https://iris.equiem.com.au/users/new",
  eqOneUrl: "https://one.getequiem.com/",
  featureToggles: {},
};

export const au: Config = {
  ...prod,
  baseUrl: "https://au.admin.getequiem.com",
  regionName: "Australia",
  region: "au",
  adminApi: "https://au.gateway.getequiem.com/graphql",
  installation: "ap-southeast-2-01",
  reportingRegion: "ap-southeast-2",
  permissionsSystem: {
    irisUrl: "https://iris.equiem.com.au/",
    checkinUrl: "https://au.checkin.getequiem.com/",
    vmsUrl: "https://au.vendor.getequiem.com/",
    cortexUrl: "https://au.console.getequiem.com/",
  },
  featureToggles: {
    fromAddress: true,
    integrations: true,
    swiftconnect: false,
    equiemEssentials: true,
    accessOne: false,
  },
  areaUnits: "sqm",
};

export const eu: Config = {
  ...prod,
  baseUrl: "https://eu.admin.getequiem.com",
  regionName: "Europe",
  region: "eu",
  adminApi: "https://eu.gateway.getequiem.com/graphql",
  installation: "eu-west-1-01",
  reportingRegion: "eu-west-1",
  permissionsSystem: {
    irisUrl: "https://iris.equiem.com.au/",
    checkinUrl: "https://eu.checkin.getequiem.com/",
    vmsUrl: "https://eu.vendor.getequiem.com/",
    cortexUrl: "https://eu.console.getequiem.com/",
  },
  featureToggles: {
    fromAddress: true,
    integrations: true,
    swiftconnect: true,
    equiemEssentials: true,
    accessOne: false,
  },
  areaUnits: "sqf",
};

export const us: Config = {
  ...prod,
  baseUrl: "https://us.admin.getequiem.com",
  regionName: "USA",
  region: "us",
  adminApi: "https://us.gateway.getequiem.com/graphql",
  installation: "us-east-1-01",
  reportingRegion: "us-east-1",
  permissionsSystem: {
    irisUrl: "https://iris.equiem.com.au/",
    checkinUrl: "https://us.checkin.getequiem.com/",
    vmsUrl: "https://us.vendor.getequiem.com/",
    cortexUrl: "https://us.console.getequiem.com/",
  },
  featureToggles: {
    fromAddress: true,
    integrations: true,
    swiftconnect: true,
    equiemEssentials: true,
    accessOne: false,
  },
  areaUnits: "sqf",
};

export const ca: Config = {
  ...prod,
  baseUrl: "https://ca.admin.getequiem.com",
  regionName: "USA",
  region: "ca",
  reportingRegion: "ca-central-1",
  adminApi: "https://ca.gateway.getequiem.com/graphql",
  installation: "ca-central-1-01",
  permissionsSystem: {
    irisUrl: "https://iris.equiem.com.au/",
    checkinUrl: "https://ca.checkin.getequiem.com/",
    vmsUrl: "https://ca.vendor.getequiem.com/",
    cortexUrl: "https://ca.console.getequiem.com/",
  },
  featureToggles: {
    fromAddress: true,
    integrations: true,
    swiftconnect: false,
    equiemEssentials: true,
    accessOne: false,
  },
  areaUnits: "sqm",
};
