import { useState, useMemo } from "react";
import {
  useCreateDestinationUploadUrlMutation,
  AdminImageType
} from "../../../../generated/admin";
import { EqMessageError } from "../../../message/EqMessage";
import { AxiosStatic } from "axios";
const png = require("pngjs/browser");

export interface UseImageUploadActionsProps {
  existingPhoto: string | null;
  axios: AxiosStatic;
  restrictions?: {
    height: number;
    width: number;
    alphaChannel?: boolean;
  };
  addToForm: (newpic: string) => void;
  removeFromForm: () => void;
}

/**
 * Image upload action.
 */
export function useImageUploadActions({
  existingPhoto,
  axios,
  restrictions,
  addToForm,
  removeFromForm,
}: UseImageUploadActionsProps) {
  const [uploading, setUploading] = useState(false);
  const [displayPic, setDisplayPic] = useState(existingPhoto);
  const [uploadProgress, setUploadProgress] = useState<number | null>(null);
  const [mutation] = useCreateDestinationUploadUrlMutation();

  const onUploadProgress = (progress: { loaded?: number; total?: number }) => {
    if (progress.loaded == null || progress.total == null) {
      return;
    }

    setUploadProgress(Math.round((progress.loaded * 100) / progress.total));
  };

  return useMemo(
    () => ({
      uploadProgress,
      displayPic,
      uploading,
      onChange: async (files: File[]) => {
        if (uploading || files[0] == null) {
          return;
        }

        // Upload the image to s3.
        try {
          setUploading(true);
          if (files.length > 1) {
            throw new Error("Only support 1 image.");
          }

          if (restrictions != null) {
            const filePromises = new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = async (event) => {
                try {
                  new png.PNG().parse(
                    event?.target?.result,
                    function (error: any, pngImage: any) {
                      if (error) {
                        reject(error);
                      }
                      resolve(pngImage);
                    }
                  );
                } catch (error) {
                  reject(error);
                }
              };
              reader.onerror = (error) => {
                reject(error);
              };
              reader.readAsArrayBuffer(files[0]);
            });

            const image = (await filePromises) as any;

            if (
              image.width !== restrictions?.width &&
              image.height !== restrictions?.width
            ) {
              throw new Error(
                `File is not the correct size expected: ${restrictions?.width}px by ${restrictions?.height}px, got: ${image.width}px by ${image.height}px.`
              );
            }

            if (restrictions?.alphaChannel != null) {
              if (image._parser._metaData.alpha !== restrictions.alphaChannel) {
                throw new Error(
                  restrictions.alphaChannel
                    ? "Expected alpha channel, found no alpha channel"
                    : "Expected no alpha channel, found alpha channel"
                );
              }
            }
          }

          let contentType: AdminImageType;
          switch (files[0].type) {
            case "image/jpeg":
              contentType = AdminImageType.Jpeg;
              break;
            case "image/png":
              contentType = AdminImageType.Png;
              break;
            case "image/gif":
              contentType = AdminImageType.Gif;
              break;
            case "image/vnd.microsoft.icon":
            case "image/x-icon":
              contentType = AdminImageType.Ico;
              break;

            default:
              throw new Error("Image format is not supported");
          }

          const createUrl = await mutation({
            variables: {
              input: {
                contentType,
                filename: files[0].name,
              },
            },
          });
          if (
            createUrl.data == null ||
            createUrl.data.createDestinationUploadUrl == null
          ) {
            throw new Error("Fail creating upload url.");
          }

          setUploadProgress(0);
          const result = await axios.put(
            createUrl.data.createDestinationUploadUrl.signedUrl,
            files[0],
            {
              headers: { "Content-Type": files[0].type },
              onUploadProgress,
            }
          );
          setUploadProgress(null);

          if (result.status === 200) {
            setDisplayPic(createUrl.data.createDestinationUploadUrl.imgixUrl);
            addToForm(createUrl.data.createDestinationUploadUrl.imgixUrl);
          }
        } catch (e) {
          const error = e instanceof Error ? `Reason: ${e.message}` : "";
          setUploadProgress(null);
          EqMessageError({ text: `Fail uploading image. ${error}` });
        }

        setUploading(false);
      },
      onRemove: () => {
        setDisplayPic(null);
        removeFromForm();
      },
    }),
    [
      addToForm,
      axios,
      displayPic,
      mutation,
      removeFromForm,
      restrictions,
      uploadProgress,
      uploading,
    ]
  );
}
