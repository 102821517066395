import {
  useAc1BuildingsQuery,
  useAc1IntegrationsQuery,
  useAc1ProvidersQuery,
} from "../../../../../generated/admin";
import { OptionType } from "../../../../../model/OptionType";
import { useMemo } from "react";

export type Building = {
  visitorsEnabled: boolean;
  employeesEnabled: boolean;
  building: OptionType;
};

export type Integration = Omit<
  NonNullable<
    ReturnType<typeof useAc1IntegrationsQuery>["data"]
  >["ac1Integrations"][number],
  "uuid" | "provider"
> & { uuid: string; provider: string; buildings: Building[] };

export function useAc1Data(siteUuid: string) {
  const { data: providersData } = useAc1ProvidersQuery();
  const providerOptions: OptionType[] = providersData?.ac1Providers ?? [];

  const { data: siteBuildingsData, loading: siteBuildingsLoading } =
    useAc1BuildingsQuery({
      variables: { destinationUuid: siteUuid },
    });
  const siteBuildingOptions: OptionType[] =
    siteBuildingsData?.buildings.edges.flatMap(({ node }) =>
      node == null ? [] : [{ value: node.uuid, label: node.name }]
    ) ?? [];

  const { data: integrationsData, loading: integrationsLoading } =
    useAc1IntegrationsQuery({
      variables: { siteUuid },
    });
  const integrations: Integration[] = useMemo(
    () =>
      siteBuildingsLoading || integrationsLoading
        ? []
        : integrationsData?.ac1Integrations.map((integration) => ({
            ...integration,
            provider: integration.provider.label,
            config:
              integration.config == null
                ? null
                : typeof integration.config === "string"
                ? integration.config
                : JSON.stringify(integration.config, null, 2),
            buildings: integration.buildingIntegrations.map(
              ({ visitorsEnabled, employeesEnabled, building }) => {
                return {
                  visitorsEnabled,
                  employeesEnabled,
                  building: {
                    value: building.uuid,
                    label: building.name,
                  },
                };
              }
            ),
          })) ?? [],
    [
      integrationsData?.ac1Integrations,
      integrationsLoading,
      siteBuildingsLoading,
    ]
  );

  return { integrations, providerOptions, siteBuildingOptions };
}
