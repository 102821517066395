import React from "react";
import { Container } from "react-bootstrap";
import { FormikProps } from "formik";
import { SiteConfigFormData } from "../SiteConfigFormData";
import {
  AdminAccessControlSolution,
  SiteDetailsQuery,
} from "../../../../generated/admin";
import { Bookings } from "./Bookings";
import { VisitorManagement } from "./VisitorManagement";
import { AccessControlSolution } from "./AccessControlSolution";
import { NewAccessControlConnection } from "./AccessControlConnection/NewAccessControlConnection";
import { useUser } from "../../../user/UserContext";
import { useConfig } from "../../../../providers/ConfigProvider";
import { Requests } from "./Requests";
import { StatsProvider } from "./StatsProvider";
import { NewIntegrationConfiguration } from "./AccessOne/NewIntegrationConfiguration";

interface Props {
  methods: FormikProps<SiteConfigFormData>;
  dest: NonNullable<SiteDetailsQuery>["destination"];
}

export const ModulesSettings: React.FC<Props> = ({ methods }) => {
  const user = useUser();
  const config = useConfig();

  return (
    <Container className="mb-5">
      <Requests methods={methods} />
      <Bookings methods={methods} />
      <VisitorManagement methods={methods} />

      {
        // if access one is enabled just don't bother rendering Access Control Connection here at all
        config.featureToggles.accessOne ? null : !methods.values.featureModules
            ?.visitors?.enabled || !user.isAdmin ? null : (
          <NewAccessControlConnection siteFormData={methods} />
        )
      }

      <StatsProvider methods={methods} />

      {config.featureToggles.accessOne ? (
        <AccessControlSolution methods={methods} />
      ) : null}

      {config.featureToggles.accessOne &&
      methods.values.accessControlSettings?.solution ===
        AdminAccessControlSolution.AccessOne &&
      user.isAdmin ? (
        <NewIntegrationConfiguration
          siteUuid={methods.values.destinationUuid}
        />
      ) : null}

      {config.featureToggles.accessOne &&
      methods.values.accessControlSettings?.solution ===
        AdminAccessControlSolution.Vicinitee &&
      user.isAdmin ? (
        <NewAccessControlConnection siteFormData={methods} />
      ) : null}
    </Container>
  );
};
